<template>
    <div class="card my-1 p-2 shadow-sm">
        <div>User ID: {{ bid.userId }}</div>
        <div>Bid: {{ bid.bid }}</div>
    </div>
</template>

<script>
export default {
    name: 'RecordDetailBid',
    props: ['bid']
}
</script>
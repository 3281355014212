<template>
    <div class="card my-3 shadow-sm">
        <div class="card-header" style="text-align: left;">
            Item ID: {{ record.itemId }}
        </div>
        <div class="card-body row" style="flex: auto;">
            <div class="col-md-2">
                <img :src="`${s3URL}/${record.itemId}-thumb`" class="card-img-top" style="height: 110px; overflow: hidden;">
            </div>
            <div v-if="!showConfirmDelete" class="col-md-7 border" style="text-align: left;">
                <ul class="list-group">
                    <a>Result: {{ auctionResult }}</a>
                    <a v-if="hasWinner">Winning User: {{ winner }}</a>
                    <a v-if="hasWinner">Winning Bid: {{ winningBidAmount }} </a>
                </ul>
            </div>
            <div v-if="showConfirmDelete" class="col-md-7 border" style="text-align: left;">
                <ul v-if="showConfirmDelete" class="list-group">
                    <label for="confirmDelete" class="form-label">Please enter "Confirm Delete"</label>
                    <input type="text" class="form-control" id="confirmDelete" placeholder="Confirm Delete" v-model="deletionConfirmationInput">
                    <button class="btn btn-sm btn-outline-secondary my-1" style="margin-top: 15px" :disabled="!confirmDeleteValid" @click.prevent="confirmDelete">Confirm Delete</button>
                </ul>
            </div>
            <div class="col-md-3">
                <ul class="list-group">
                    <router-link class="btn btn-sm btn-outline-secondary my-1" :to="'/history/record/' + record._id">Details</router-link>
                    <button class="btn btn-sm btn-outline-secondary my-1" @click.prevent="toggleDeleteConfirmation">Delete</button>
                </ul>
            </div>
        </div>
        <div class="card-footer" style="text-align: left;">
            Date: {{ record.date }}
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'HistoryCard',
    props: ['record', 'userLoggedIn', 'isAdmin', 'authToken'],
    data() {
        return {
            s3URL: process.env.VUE_APP_S3BUCKETURL,
            showConfirmDelete: false,
            deletionConfirmationInput: '',
        }
    },
    computed: {
        finalBid() {
            if (this.record.history.length == 0) {
                return null;
            } else if (this.record.history.length == 1) {
                return this.record.history[0];
            }
            return this.record.history[this.record.history.length-2];
        },
        auctionResult() {
            if (this.finalBid == null) {
                console.log("0 length");
                return "error";
            }
            if (this.finalBid.userId == "0") {
                console.log("no bid");
                return "no bid";
            }
            if (this.finalBid.bid < this.record.reserve) {
                console.log("below reserve");
                return "not sold";
            }
            return "sold";
        },
        hasWinner() {
            return this.auctionResult == "sold";
        },
        winner() {
            if (this.hasWinner) {
                return this.finalBid.userId;
            }
            return null
        },
        winningBidAmount() {
            if (this.hasWinner) {
                return this.finalBid.bid;
            }
            return null
        },
        confirmDeleteValid() {
            return this.deletionConfirmationInput == "Confirm Delete";
        }
    },
    methods: {
        toggleDeleteConfirmation() {
            if (this.showConfirmDelete == true) {
                this.showConfirmDelete = false;
            } else {
                this.showConfirmDelete = true;
            }
        },
        confirmDelete() {
            axios.delete(`${process.env.VUE_APP_SERVERURL}/history/${this.record._id}`, {
                headers: {
                    'x-auth-token': this.authToken
                }
            })
            .then((res) => {
                if (res.data) {
                    alert("Successfully Deleted");
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    }
}
</script>
<template>
    <div class="card my-3 shadow-sm">
        <div class="card-header" style="text-align: left;">
            Item ID: {{ record.itemId }}
        </div>
        <div class="card-body row" style="flex: auto;">
            <div class="col-md-2">
                <img :src="`${s3URL}/${record.itemId}-thumb`" class="card-img-top" style="height: 110px; overflow: hidden;">
            </div>
            <div class="col-md-10 border" style="text-align: left;">
                <h4 v-if="hasWinner" class="mt-2">Auction (Sold)</h4>
                <h4 v-if="!hasWinner" class="mt-2">Auction (Not sold)</h4>
                <ul class="list-group my-2 p-2 border">
                    <a>Starting Bid: {{ record.startingBid }}</a>
                    <a>Reserve: {{ record.reserve }}</a>
                    <a>Estimate Low: {{ record.estimateLow }}</a>
                    <a>Estimate High: {{ record.estimateHigh }}</a>
                </ul>
                <h4 v-if="hasWinner" class="mt-2">Winner</h4>
                <ul v-if="winningUser" class="list-group my-2 p-2 border">
                    <a>ID: {{ winningUser._id }}</a>
                    <a>Username: {{ winningUser.name }}</a>
                    <a>Mobile: {{ winningUser.email }}</a>
                    <a>Email: {{ winningUser.mobile }}</a>
                </ul>
                <ul class="list-group mt-2">
                    <h4>History</h4>
                    <RecordDetailBid
                        v-for="bid in record.history"
                        :bid="bid"
                    ></RecordDetailBid>
                </ul>
            </div>
        </div>
        <div class="card-footer" style="text-align: left;">
            Date: {{ record.date }}
        </div>
    </div>
</template>

<script>
import RecordDetailBid from '@/components/RecordDetailBid.vue';
import axios from 'axios';
export default {
    name: 'RecordDetailCard',
    props: ['record', 'winningUser', 'userLoggedIn', 'isAdmin', 'authToken'],
    data() {
        return {
            s3URL: process.env.VUE_APP_S3BUCKETURL,
            winner: null,
        }
    },
    computed: {
        finalBid() {
            if (this.record.history.length == 0) {
                return null;
            } else if (this.record.history.length == 1) {
                return this.record.history[0];
            }
            return this.record.history[this.record.history.length-2];
        },
        auctionResult() {
            if (this.finalBid == null) {
                console.log("0 length");
                return "error";
            }
            if (this.finalBid.userId == "0") {
                console.log("no bid");
                return "no bid";
            }
            if (this.finalBid.bid < this.record.reserve) {
                console.log("below reserve");
                return "not sold";
            }
            return "sold";
        },
        hasWinner() {
            return this.auctionResult == "sold";
        },
        winner() {
            if (this.hasWinner) {
                return this.finalBid.userId;
            }
            return null
        },
        winningBidAmount() {
            if (this.hasWinner) {
                return this.finalBid.bid;
            }
            return null
        },
        confirmDeleteValid() {
            return this.deletionConfirmationInput == "Confirm Delete";
        }
    },
    components: {
        RecordDetailBid
    }
}
</script>
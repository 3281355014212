<template>
    <div class="row my-5">
        <h2 class="col-md-2 py-1">History</h2>
        <div class="col-md-2 py-1">
            <router-link class="btn btn-outline-secondary" to="/item/create">Create Item</router-link>
        </div>
        <div class="col-md-2 py-1">
            <router-link class="btn btn-outline-secondary" to="/listed">Listed</router-link>
        </div>
        <div class="col-md-2 py-1">
            <router-link class="btn btn-outline-secondary" to="/unlisted">Unlisted</router-link>
        </div>
        <div class="col-md-4 py-1">
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Coming Soon">
                <button class="input-group-text">
                    Search
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HistoryHeader'
}
</script>
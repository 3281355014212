<template>
    <navbar
        @log-out="logOut"
        :user-logged-in="userLoggedIn"
        :is-admin="isAdmin"
    ></navbar>
    <div class="row row-cols-1 row-cols-md-2 g-2">
        <div class="container-fluid" style="flex: auto; max-width: 900px;">
            <RecordDetailHeader></RecordDetailHeader>
            <RecordDetailCard
                v-if="record"
                :record="record"
                :user-logged-in="userLoggedIn"
                :is-admin="isAdmin"
                :auth-token="authToken"
                :winning-user="winningUser"
            ></RecordDetailCard>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Navbar from '@/components/Navbar.vue';
import RecordDetailHeader from '@/components/RecordDetailHeader.vue';
import RecordDetailCard from '@/components/RecordDetailCard.vue';
import axios from 'axios'
export default {
    name: 'RecordDetailView',
    props: ['userLoggedIn', 'isAdmin', 'authToken'],
    data() {
        return {
            recordId: "",
            record: null,
            winningUser: null
        }
    },
    emits: {
        logOut() {
            return true;
        }
    },
    mounted() {
        this.recordId = this.$route.params.id;
        
        axios.get(`${process.env.VUE_APP_SERVERURL}/history/${this.recordId}`, {
        })
        .then((res) => {
            this.record = res.data;

            if (this.record.history.length >= 2) {
                if (this.record.history[this.record.history.length-2].bid >= this.record.reserve) {
                    let winnerID = this.record.history[this.record.history.length-2].userId
                    axios.get(`${process.env.VUE_APP_SERVERURL}/users/${winnerID}`, {
                        headers: {
                            'x-auth-token': this.authToken
                        }
                    })
                    .then((res) => {
                        this.winningUser = res.data;
                    })
                    .catch(err => console.log(err))
                }
            }
        })
        .catch(err => console.log(err))
    },
    methods: {
        logOut() {
            this.$emit('logOut');
        },
    },
    components: {
        Header,
        Navbar,
        RecordDetailHeader,
        RecordDetailCard
    }
}
</script>
<template>
    <div class="card my-3 shadow-sm">
        <div class="card-header" style="text-align: left;">
            {{ item.name }}
        </div>
        <div class="card-body row" style="flex: auto;">
            <div class="col-md-2">
                <img :src="`${s3URL}/${item._id}-thumb`" class="card-img-top" style="height: 110px; overflow: hidden;">
            </div>
            <div class="col-md-3 border" style="text-align: left;">
                <ul class="list-group">
                    <a>Starting Bid: {{ auction.startingBid }}</a>
                    <a>Reserve Price: {{ auction.reserve }} </a>
                    <a v-if="auction.isBidQueued">Highest Queued Bid: {{ auction.queuedBid }} </a>
                </ul>
            </div>
            <div class="col-md-4 border" style="text-align: left;">
                <ul v-if="showConfirmDelete" class="list-group">
                    <label for="confirmDelete" class="form-label">Please enter "Confirm Delete"</label>
                    <input type="text" class="form-control" id="confirmDelete" placeholder="Confirm Delete" v-model="deletionConfirmationInput">
                    <button class="btn btn-sm btn-outline-secondary my-1" style="margin-top: 15px" :disabled="!confirmDeleteValid" @click.prevent="confirmDelete">Confirm Delete</button>
                </ul>
            </div>
            <div class="col-md-3">
                <ul class="list-group">
                    <button class="btn btn-sm btn-outline-secondary my-1" :disabled="auction.isComplete" @click.prevent="createAuction">Start Auction</button>
                    <button class="btn btn-sm btn-outline-secondary my-1" @click.prevent="toggleConfirmation">Delete</button>
                    <router-link class="btn btn-sm btn-outline-secondary my-1 disabled" to="/">Edit</router-link>
                </ul>
            </div>
        </div>
        <div class="card-footer" style="text-align: left;">
            ID: {{ item._id }}
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'ListedCard',
    props: ['item', 'auction','userLoggedIn', 'isAdmin', 'authToken'],
    data() {
        return {
            s3URL: process.env.VUE_APP_S3BUCKETURL,
            showConfirmDelete: false,
            deletionConfirmationInput: ''
        }
    },
    computed: {
        confirmDeleteValid() {
            return this.deletionConfirmationInput == "Confirm Delete"
        }
    },
    methods: {
        createAuction() {
            /*
                req is:
                startBid: starting bid
                reserve: minimum sale price
                estimateLow: low value estimate
                estimateHigh: high value estimate
                delay: delay in seconds
            */

            axios.post(`${process.env.VUE_APP_SERVERURL}/live-auctions/${this.item._id}`, {
                delay: 0
            },
            {
                headers: {
                    'x-auth-token': this.authToken
                }
                
            })
            .then((res) => {
                alert("Auction started")
            })
            .catch(err => {
                console.log(err);
            })
        },
        toggleConfirmation() {
            if (this.showConfirmDelete == true) {
                this.showConfirmDelete = false;
            } else {
                this.showConfirmDelete = true;
            }
        },
        confirmDelete() {
            axios.delete(`${process.env.VUE_APP_SERVERURL}/auctions/${this.auction._id}`, {
                headers: {
                    'x-auth-token': this.authToken
                }
            })
            .then((res) => {
                if (res.data) {
                    alert("Successfully Deleted");
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    }
}
</script>
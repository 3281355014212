<template>
    <navbar
        @log-out="logOut"
        :user-logged-in="userLoggedIn"
        :is-admin="isAdmin"
    ></navbar>
    <div class="row row-cols-1 row-cols-md-2 g-2">
        <div class="container-fluid" style="flex: auto; max-width: 900px;">
            <HistoryHeader></HistoryHeader>
            <HistoryCard
                v-for="record in history"
                :record="record"
                :user-logged-in="userLoggedIn"
                :is-admin="isAdmin"
                :auth-token="authToken"
            ></HistoryCard>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Navbar from '@/components/Navbar.vue';
import HistoryHeader from '@/components/HistoryHeader.vue';
import HistoryCard from '@/components/HistoryCard.vue';
import axios from 'axios'
export default {
    name: 'HistoryView',
    props: ['userLoggedIn', 'isAdmin', 'authToken'],
    data() {
        return {
            history: []
        }
    },
    emits: {
        logOut() {
            return true;
        }
    },
    mounted() {        
        axios.get(`${process.env.VUE_APP_SERVERURL}/history`, {
        })
        .then((res) => {
            this.history = res.data;
        })
        .catch(err => console.log(err))
    },
    methods: {
        logOut() {
            this.$emit('logOut');
        },
    },
    components: {
        Header,
        Navbar,
        HistoryHeader,
        HistoryCard
    }
}
</script>
<template>
    <div class="card my-3 shadow-sm">
        <div class="card-header" style="text-align: left;">
            {{ item.name }}
        </div>
        <div class="card-body row" style="flex: auto;">
            <div class="col-md-2">
                <img :src="`${s3URL}/${item._id}-thumb`" class="card-img-top" style="height: 110px; overflow: hidden;">
            </div>
            <div class="col-md-7 border" style="text-align: left;">
                <ul v-if="showConfirmDelete" class="list-group">
                    <label for="confirmDelete" class="form-label">Please enter "Confirm Delete"</label>
                    <input type="text" class="form-control" id="confirmDelete" placeholder="Confirm Delete" v-model="deletionConfirmationInput">
                    <button class="btn btn-sm btn-outline-secondary my-1" style="margin-top: 15px" :disabled="!confirmDeleteValid" @click.prevent="confirmDelete">Confirm Delete</button>
                </ul>
                <ul v-if="showCreateAuctionForm" class="list-group">
                    <div class="border p-2 mt-2">
                        <label for="startingBid" class="form-label">Starting Bid</label>
                        <input type="text" class="form-control" id="startingBid" placeholder="Starting Bid" v-model="startingBid">
                    </div>
                    <div class="border p-2 mt-2">
                        <label for="reserve" class="form-label">Reserve</label>
                        <input type="text" class="form-control" id="reserve" placeholder="Reserve" v-model="reserve">
                    </div>
                    <div class="border p-2 mt-2">
                        <label for="estimateHigh" class="form-label">Estimage High</label>
                        <input type="text" class="form-control" id="estimateHigh" placeholder="Estimage High" v-model="estimateHigh">
                    </div>
                    <div class="border p-2 mt-2">
                        <label for="estimateLow" class="form-label">Estimage Low</label>
                        <input type="text" class="form-control" id="estimateLow" placeholder="Estimage Low" v-model="estimateLow">
                    </div>
                    <button type="submit" class="btn btn-outline-secondary mt-2 mb-2" style="width: 100%;" :disabled="!createAuctionValid" @click.prevent="submitCreateAuction">Create Auction</button>
                </ul>
            </div>
            <div class="col-md-3">
                <ul class="list-group">
                    <button class="btn btn-sm btn-outline-secondary my-1" @click.prevent="toggleCreateAuction">Show Create Auction</button>
                    <button class="btn btn-sm btn-outline-secondary my-1" @click.prevent="toggleDeleteConfirmation">Delete</button>
                    <router-link class="btn btn-sm btn-outline-secondary my-1 disabled" to="/">Edit</router-link>
                </ul>
            </div>
        </div>
        <div class="card-footer" style="text-align: left;">
            ID: {{ item._id }}
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'UnlistedCard',
    props: ['item', 'userLoggedIn', 'isAdmin', 'authToken'],
    data() {
        return {
            s3URL: process.env.VUE_APP_S3BUCKETURL,
            showConfirmDelete: false,
            showCreateAuctionForm: false,
            deletionConfirmationInput: '',
            startingBid:'',
            reserve:'',
            estimateHigh:'',
            estimateLow:''
        }
    },
    computed: {
        confirmDeleteValid() {
            return this.deletionConfirmationInput == "Confirm Delete";
        },
        createAuctionValid() {
            return (this.startingBid && this.reserve && this.estimateHigh && this.estimateLow);
        }
    },
    methods: {
        toggleDeleteConfirmation() {
            this.showCreateAuctionForm = false;
            if (this.showConfirmDelete == true) {
                this.showConfirmDelete = false;
            } else {
                this.showConfirmDelete = true;
            }
        },
        toggleCreateAuction() {
            this.showConfirmDelete = false;
            if (this.showCreateAuctionForm == true) {
                this.showCreateAuctionForm = false;
            } else {
                this.showCreateAuctionForm = true;
            }
        },
        submitCreateAuction() {
            console.log({
                itemId: this.item._id,
                startingBid: this.startingBid,
                reserve: this.reserve,
                estimateLow: this.estimateLow,
                estimateHigh: this.estimateHigh,
            })
            axios.post(`${process.env.VUE_APP_SERVERURL}/auctions`, {
                itemId: this.item._id,
                startingBid: this.startingBid,
                reserve: this.reserve,
                estimateLow: this.estimateLow,
                estimateHigh: this.estimateHigh,
            },
            {
                headers: {
                    'x-auth-token': this.authToken
                }
            })
            .then((res) => {
                alert("Creation successfull");
            })
            .catch(err => {
                console.log(err);
            })
        },
        confirmDelete() {
            // TODO
            axios.delete(`${process.env.VUE_APP_SERVERURL}/items/${this.item._id}`, {
                headers: {
                    'x-auth-token': this.authToken
                }
            })
            .then((res) => {
                if (res.data) {
                    alert("Successfully Deleted");
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    }
}
</script>
<template>
    <navbar
        @log-out="logOut"
        :user-logged-in="userLoggedIn"
        :is-admin="isAdmin"
    ></navbar>
    <div class="row row-cols-1 row-cols-md-2 g-2">
        <div class="container-fluid" style="flex: auto; max-width: 900px;">
            <ListedHeader></ListedHeader>
            <ListedCard
                v-if="auctions"
                v-for="item in items"
                :key="item._id"
                :item="item"
                :user-logged-in="userLoggedIn"
                :is-admin="isAdmin"
                :auth-token="authToken"
                :auction="getAuction(item._id)"
            ></ListedCard>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Navbar from '@/components/Navbar.vue';
import ListedHeader from '@/components/ListedHeader.vue';
import ListedCard from '@/components/ListedCard.vue';
import axios from 'axios'
export default {
    name: 'ListedView',
    props: ['userLoggedIn', 'isAdmin', 'authToken'],
    data() {
        return {
            items: [],
            auctions: [],
        }
    },
    emits: {
        logOut() {
            return true;
        }
    },
    mounted() {
        /* 
            TODO
            - Get all listed auctions
             - Get all their thumbnails
            - Auction Controls
             - Start
             - Delete
             - Edit?
        */
        
        axios.get(`${process.env.VUE_APP_SERVERURL}/items`, {
        })
        .then((res) => {
            let tempItems = res.data;
            axios.get(`${process.env.VUE_APP_SERVERURL}/auctions/full`, {})
            .then((res) => {
                this.auctions = res.data;
                let auctionIDs = [];
                for (let auction of res.data) {
                    auctionIDs.push(auction.itemId);
                }
                for (let tempItem of tempItems) {
                    if (auctionIDs.includes(tempItem._id)) {
                        this.items.push(tempItem);
                        console.log("Listed");
                    } else {
                        console.log("Unlisted");
                    }
                }
            }
            )
        })
        .catch(err => console.log(err))
    },
    methods: {
        getAuction(itemId) {
            for (let auction of this.auctions) {
                if (auction.itemId == itemId) {
                    return auction;
                }
            }
        },
        logOut() {
            this.$emit('logOut');
        },
    },
    components: {
        Header,
        Navbar,
        ListedHeader,
        ListedCard
    }
}
</script>
<template>
    <div class="col">
        <div class="card shadow-sm">
            <router-link class="card-header" :to="'/item/' + item._id" style="text-decoration: none;">
                <p class="card-text">{{ item.name }}</p>
            </router-link>
            <router-link class="card-header" :to="'/item/' + item._id" style="text-decoration: none;">
                <img :src="`${s3URL}/${item._id}-thumb`" class="card-img-top" style="height: 200px; overflow: hidden;">
            </router-link>
            <router-link v-if="auction.isComplete" class="card-body" :to="'/item/' + item._id" style="text-decoration: none;">
                <p class="card-text">Auction has concluded</p>
            </router-link>
            <router-link v-if="!auction.isComplete && !auction.isBidQueued" class="card-body" :to="'/item/' + item._id" style="text-decoration: none;">
                <p class="card-text">Bids Queued: 0</p>
            </router-link>
            <router-link v-if="!auction.isComplete && auction.isBidQueued && userLoggedIn" class="card-body" :to="'/item/' + item._id" style="text-decoration: none;">
                <div class="row">
                    <div class="card-text col-6">Bids Queued: {{ this.auction.queuedBidCount }}</div>
                    <div class="card-text col-6">Highest: ${{ this.auction.queuedBid }}</div>
                </div>
            </router-link>
            <router-link v-if="!auction.isComplete && auction.isBidQueued && !userLoggedIn" class="card-body" :to="'/item/' + item._id" style="text-decoration: none;">
                <div class="row">
                    <div class="card-text col-12">Bids Queued: {{ this.auction.queuedBidCount }}</div>
                </div>
            </router-link>

            <router-link v-if="status == 'active'" class="card-body border-top" :to="'/item/' + item._id" style="text-decoration: none;">&#129001 This auction is live!</router-link>
            <router-link v-else-if="status == 'delay'" class="card-body border-top" :to="'/item/' + item._id" style="text-decoration: none;">&#129000 This auction will be live soon!</router-link>
            <router-link v-else class="card-body border-top" :to="'/item/' + item._id" style="text-decoration: none;">&#128997 This auction is not currently live.</router-link>
            
        </div>
    </div>
</template>

<script>
export default {
    props: ['userLoggedIn', 'item', 'auction', 'status'],
    name: 'SmallAuctionCard',
    data() {
        return {
            s3URL: process.env.VUE_APP_S3BUCKETURL
        }
    },
}
</script>
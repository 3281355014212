<template>
    <navbar
        @log-out="logOut"
        :user-logged-in="userLoggedIn"
        :is-admin="isAdmin"
    ></navbar>
    <div class="row row-cols-1 row-cols-md-2 g-2">
        <div class="container-fluid" style="flex: auto; max-width: 900px;">
            <UnlistedHeader></UnlistedHeader>
            <UnlistedCard
                v-if="auctions"
                v-for="item in items"
                :key="item._id"
                :item="item"
                :user-logged-in="userLoggedIn"
                :is-admin="isAdmin"
                :auth-token="authToken"
            ></UnlistedCard>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Navbar from '@/components/Navbar.vue';
import UnlistedHeader from '@/components/UnlistedHeader.vue';
import UnlistedCard from '@/components/UnlistedCard.vue';
import axios from 'axios'
export default {
    name: 'UnlistedView',
    props: ['userLoggedIn', 'isAdmin', 'authToken'],
    data() {
        return {
            items: [],
            auctions: [],
        }
    },
    emits: {
        logOut() {
            return true;
        }
    },
    mounted() {
        axios.get(`${process.env.VUE_APP_SERVERURL}/items`, {
        })
        .then((res) => {
            let tempItems = res.data;
            axios.get(`${process.env.VUE_APP_SERVERURL}/auctions/full`, {})
            .then((res) => {
                this.auctions = res.data;
                let auctionIDs = [];
                for (let auction of res.data) {
                    auctionIDs.push(auction.itemId);
                }
                for (let tempItem of tempItems) {
                    if (auctionIDs.includes(tempItem._id)) {
                        console.log("Listed");
                    } else {
                        this.items.push(tempItem);
                        console.log("Unlisted");
                    }
                }
            }
            )
        })
        .catch(err => console.log(err))
    },
    methods: {
        logOut() {
            this.$emit('logOut');
        },
    },
    components: {
        Header,
        Navbar,
        UnlistedHeader,
        UnlistedCard
    }
}
</script>